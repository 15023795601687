import React from "react";

const Popup = ({ isOpen, closePopup, imageSrc, text }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center pop-animate">
      <div
        className="absolute inset-0 bg-black opacity-60"
        onClick={closePopup}
      ></div>

      <div className="relative bg-white rounded-lg shadow-lg z-10 max-w-lg mx-auto w-11/12 sm:w-full">
        {/* Close Button */}
        <button
          className="absolute top-0 right-0 mt-2 mr-2 text-white font-bold text-2xl leading-none z-20 sm:mt-0 sm:mr-2 sm:text-3xl"
          onClick={closePopup}
        >
          &times;
        </button>

        {/* Image */}
        <div className="w-full">
          {imageSrc && (
            <img src={imageSrc} alt="" className="w-full rounded-t-lg" />
          )}
        </div>

        {/* Text */}
        <div className="p-4 bg-gradient-to-b from-transparent via-white to-white">
          {text && (
            <p className="text-center text-black text-base sm:text-lg md:text-xl font-semibold leading-relaxed tracking-wide">
              {text}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Popup;
