import React from "react";

const Footer = () => {
  return (
    <div className="bg-[#071923] text-white py-3 sm:py-4 md:py-5 lg:py-6 font-poppins w-full">
      <div className="text-center text-xs sm:text-sm md:text-base leading-tight sm:leading-snug md:leading-relaxed lg:leading-7 px-3 sm:px-4 md:px-6">
        © patervasileiostsiounis.com 2024 | Υλοποίηση
        <a
          href="https://www.georgethecoder.com/"
          className="text-custom-new-blue pl-2 hover:text-white inline-block sm:inline"
        >
          Γεώργιος Διάκος
        </a>
        {/* <span role="img" aria-label="hands" className="inline-block ml-2">
          🙏🏻
        </span> */}
      </div>
    </div>
  );
};

export default Footer;
