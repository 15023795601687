import React from "react";

import book from "../images/book.png";
import book_back from "../images/book_back.png";

function Book() {
  const handleDownload = () => {
    const downloadLink = document.createElement("a");
    downloadLink.href = process.env.PUBLIC_URL + "/pvasileios_book.pdf";

    downloadLink.download = "pvasileios_book.pdf";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div className="App bg-new-blue text-white font-serif">
      <main className="px-8 py-8 md:px-16">
        <section className="mb-12 md:flex ">
          <div className="md:w-1/2">
            <h2 className="text-2xl md:text-4xl mb-4 md:mb-6 border-b-4 border-priestly-green inline-block">
              Αμυγδαλή Καρδίτσας
            </h2>
            <p className="text-lg sm:text-xl leading-relaxed">
              Το Παρόν Βιβλίο γράφτηκε από τον ίδιο τον †Πρωτοπρεσβύτερο
              Βασίλειο Τσιούνη για τους εξής λόγους:
            </p>
            <br />
            <li>
              Να κάνει γνωστή στις νέες γενιές την εκκλησιαστική ιστορία της
              Αμυγδαλής Καρδίτσας που, αν και μικρό χωριό, έχει να επιδείξει
              όμορφα εκκλησιαστικά έργα για τα οποία πολλάκις το χωριό έχει
              δεχτεί συγχαρητήρια.
            </li>
            <br />
            <li>
              Να απευθυνθεί από καρδιάς ένα μεγάλο ΕΥΧΑΡΙΣΤΩ σε όλους τους
              δωρητές που με την αμέριστη υλική αλλά και ψυχική συμπαράσταση
              τους στάθηκαν συνοδοιπόροι και ακούραστοι συνεργάτες στον αγώνα
              του για να πάρουν σάρκα και οστά όλα αυτά τα εκκλησιαστικά έργα
              ώστε να κοσμήσουν το αγαπημένο μας χωριό και να συνεχίζουμε ως
              σήμερα να τα λειτουργούμε " του Κυρίου συνεργούντος " για χάρη
              όλων σας και των απογόνων μας.
            </li>
            <br />
            <li>
              Να αποκαταστήσει με αποδείξεις την αλήθεια για όσα αναληθή και
              ανακριβή για το πρόσωπο του είχαν γραφτεί σχετικά με τις
              εξωτερικές παρεμβάσεις και αλλοιώσεις του πάνσεπτου Ιερού Ναού του
              πολιούχου του χωριού Αγ. Δημητρίου και την δική του ανάμειξη στο
              βιβλίο του συγχωριανού του Γεώργιου Ηλία Καρκανιά.
            </li>

            <br />
            <br />
            <p className="text-lg sm:text-xl md:text-left text-center  leading-relaxed">
              Για να κατεβάσετε και να διαβάσετε το βιβλίο , πατήστε πάνω στο
              βιβλίο!
            </p>
          </div>

          <div className="md:w-1/2 flex justify-center items-center md:py-0 py-12">
            <div className="book-container ">
              <div
                className="book shadow-photo "
                onClick={handleDownload}
                style={{ cursor: "pointer" }}
              >
                <img src={book} alt="Front of the book" />
                <img src={book_back} alt="Back of the book" className="back" />
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Book;
