import React, { useState } from "react";
import { testimonials } from "./testimonialsData"; // Assume this is an array of testimonial objects

const TestimonialCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [showModal, setShowModal] = useState(false); // State to control modal visibility

  const nextTestimonial = () => {
    setActiveIndex((current) => (current + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setActiveIndex(
      (current) => (current - 1 + testimonials.length) % testimonials.length
    );
  };

  const toggleModal = () => setShowModal(!showModal); // Function to toggle modal visibility

  return (
    <div className="App text-white flex flex-col items-center justify-center bg-custom-blue py-12 px-4 sm:px-6 lg:px-8 font-serif">
      <h2 className="text-2xl md:text-4xl mb-4 md:mb-6 border-b-4 border-priestly-green">
        Μερικά Λόγια
      </h2>
      <div className="flex justify-center w-full px-4 sm:px-0">
        <button
          onClick={prevTestimonial}
          aria-label="Previous"
          className="text-blue-500 hover:text-blue-600 mr-2 md:mr-4"
        >
          {/* SVG for left arrow */}
        </button>
        <div className="max-w-xl flex flex-col items-center">
          <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4">
            {/* Image with onClick handler to toggle modal */}
            <img
              src={testimonials[activeIndex].logoUrl}
              alt="Logo"
              className="h-12 w-12 rounded-full cursor-pointer" // Add cursor-pointer to indicate it's clickable
              onClick={toggleModal}
            />
            <p className="text-xl md:text-lg lg:text-xl font-semibold text-center md:text-left">
              {testimonials[activeIndex].quote}
            </p>
          </div>
          <div className="mt-4 text-center md:text-left">
            <p className="font-bold">{testimonials[activeIndex].author}</p>
            <p className="text-sm">{testimonials[activeIndex].title}</p>
          </div>
        </div>
        <button
          onClick={nextTestimonial}
          aria-label="Next"
          className="text-custom-new-blue ml-2 md:ml-4"
        >
          {/* SVG for right arrow */}
        </button>
      </div>
      {/* Modal/Overlay for the enlarged image */}
      {showModal && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
          onClick={toggleModal}
        >
          <img
            src={testimonials[activeIndex].logoUrl}
            alt="Enlarged Logo"
            className="max-w-4xl max-h-screen cursor-pointer"
            onClick={(e) => e.stopPropagation()}
          />
        </div>
      )}
    </div>
  );
};

export default TestimonialCarousel;
