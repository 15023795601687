import logo from "../images/orkomosia.png";

export const testimonials = [
  {
    id: 1,
    author: "Γεώργιος Διάκος",
    title: "Εγγονός του †Πρωτοπρεσβύτερου Βασίλειου Τσιούνη ",
    quote:
      "Ο Παππούς μου , μου μετέδωσε μια συμβουλή που έχει μείνει βαθιά στην μνήμη μου. Με απλότητα και σοφία. Είπε: '' Γιώργο μου, ταξίδεψε όλο τον κόσμο και ενω το κάνεις , αντιστάσου στην οθόνη του κινητού σου, να κοιτάς εξω την ποικιλομορφία και το θαύμα που βρίσκεται πέρα από τα όρια της τεχνολογίας. '' Παππού η απουσία σου είναι μεγάλη! ",
    logoUrl: logo,
  },
];
