import React from "react";
import { useState } from "react";
import Popup from "../components/PopWin";
import mySecondFather from "../images/pvasileios.png";
import agios from "../images/agios_dimitrios.jpg";
import { Helmet } from "react-helmet";

function Home() {
  const [isPopupOpen, setPopupOpen] = useState(true);

  const closePopup = () => setPopupOpen(false);

  return (
    <>
      <Helmet>
        <title>Πάτερ Βασίλειος Τσιούνης</title>
        <link rel="icon" href={mySecondFather} type="image/png" />
      </Helmet>

      <div className="App bg-new-blue text-white font-serif">
        <Popup
          isOpen={isPopupOpen}
          closePopup={closePopup}
          imageSrc={agios}
          text="Η παρόν ιστοσελίδα δημιουργήθηκε με σκοπό να τιμήσω την μνήμη του πολυαγαπημένου μου παππού †Πρωτοπρεσβύτερο Βασίλειο Τσιούνη."
        ></Popup>
        <header className="text-center py-8 px-6 bg-sacred-gold shadow-lg header-animate">
          <h1 className="text-lg md:text-2xl font-bold uppercase tracking-widest">
            🕊️ Στην μνημη του πολυαγαπημενου μου Παππου †Πρωτοπρεσβυτερο
            Βασιλειο Τσιουνη 🕊️
          </h1>
        </header>

        <main className="px-8 py-12 md:px-16">
          <section className="mb-12 md:flex">
            <div className="md:w-1/2">
              <h2 className="text-2xl md:text-4xl mb-4 md:mb-6 border-b-4 border-priestly-green inline-block">
                Βιογραφικό Σημείωμα
              </h2>
              <p className="text-lg sm:text-xl leading-relaxed">
                Ο Πατήρ Βασίλειος Τσιούνης γεννήθηκε στην
                <a
                  href="https://el.wikipedia.org/wiki/%CE%91%CE%BC%CF%85%CE%B3%CE%B4%CE%B1%CE%BB%CE%AE_%CE%9A%CE%B1%CF%81%CE%B4%CE%AF%CF%84%CF%83%CE%B1%CF%82"
                  className="text-custom-new-blue pl-1 md:pl-2 hover:text-white"
                >
                  Αμυγδαλή Καρδίτσας
                </a>{" "}
                στις 18 Οκτωβρίου 1946, μοναδικό παιδί της οικογενείας του
                Στεφάνου Κων. Τσιούνη και της Ελένης Στεργίου Παππά από το
                Ελληνόκαστρο και τρισέγγονος του Ιερέα παπα-Νικόλα Παππά από το
                Ελληνόκαστρο. Τελείωσε το δημοτικό σχολείο του χωριού του και
                αποφοίτησε από το γυμνάσιο Καρδίτσας στην τρίτη Γυμνασίου. Από
                μικρό παιδί είχε μεγάλη αγάπη για το χωριό του και για την
                εκκλησία του Χριστού μας. Για τον ίδιο ήταν πηγή ζωής να
                λειτουργεί η Εκκλησία του χωριού και επειδή δεν είχε Ιερέα , για
                το λόγο αυτό πήγαινε και παρακαλούσε συνταξιούχους ιερείς, όπως
                τον παπα- Ευάγγελο Νικάκη από το Ελληνόκαστρο, τον παπα-Κώστα
                Πάνου από το Μαυρομάτι και τους Ηγούμενους της Μονής Κορώνης και
                Γεωργίου Καραισκάκη να έρθουν να λειτουργήσουν κάνοντας πάντα ο
                ίδιος τη μεταφορά τους με το γαιδουράκι του. Όταν η προσπάθεια
                του αποτύγχανε πήγαινε ο ίδιος και εκκλησιαζόταν σε άλλα χωριά ,
                στο Ελληνόκαστρο , στον Ελληνόπυργο κ.α. Στην εκκλησία του Αγίου
                Δημητρίου του χωριού , που το όνειρο του ήταν να την κάνει το
                στολίδι του χωριού αν τον αξίωνε ο Θεός να φορέσει το Σχήμα του
                Χριστού μας, έκανε πάντα χρέη νεωκόρου και ιεροψάλτη.
              </p>{" "}
              <br />
              <p className="text-lg sm:text-xl leading-relaxed">
                Το όνειρο του πραγματοποιήθηκε όταν χειροτονήθηκε Διάκονος στις
                20 Δεκεμβρίου 1970 και Πρεσβύτερος στις 24 Δεκεμβρίου 1970 από
                το μακαριστό Μητροπολίτη Θεσσαλιώτιδος και Φαναριοφερσάλων
                Κωνσταντίνο, πνευματικού του πατέρα, και τοποθετήθηκε τον
                Ιανουάριο του 1971 εφημέριος στην ενορία της Αμυγδαλής
                Καρδίτσας, όπου στα 24 του χρόνια ανέλαβε τη διοίκηση και την
                διαποίμανσή της. Διατέλεσε ως λαικός και στη συνέχεια ως ιερέας
                οικονομικός εκπρόσωπος της Ιεράς Μητρόπολης κατά τα έτη
                1970-1974 και είχε την τιμή να λάβει δυο οφίκια από τον
                μακαριστό Μητροπολίτη Κλεόπα αυτό του Οικονόμου στις 21
                Αυγούστου 1980 και το οφίκιο του Πρωτοπρεσβύτερου στις 29
                Απριλίου 1985.
              </p>{" "}
              <br />
              <p className="text-lg sm:text-xl leading-relaxed">
                Συνταξιοδοτήθηκε το 2007 για λόγους υγείας. Όλα αυτά τα χρόνια
                στάθηκε πολύτιμος συνοδοιπόρος δίπλα του η σύζυγος του Παρθενόπη
                Ηλία Φώτη, από την Κρυοπηγή Καρδίτσας με την οποία ήταν
                νυμφευμένος από τις 16 Φεβρουαρίου 1969 και η οποία του χάρισε
                δυο κόρες , την Ελένη και την Σοφία, που ο Θεός τον αξίωσε να
                τις δεί να δημιουργούν τις δικές τους οικογένειες και να του
                χαρίζουν τέσσερα υπέροχα εγγόνια , την Μαρία , τον Άγγελο , τον
                Βασίλη και τον Γιώργο.
              </p>
              <br />
              <p className="text-lg sm:text-xl text-center md:text-left leading-relaxed">
                Aπεβίωσε στις 8 Νοεμβρίου 2017.
              </p>
              <br />
            </div>
            <div className="md:w-1/2 md:py-24 pl-2 md:pl-32 responsive-image">
              <img
                src={mySecondFather}
                alt="Πατήρ Βασίλειος Τσιούνης"
                className="rounded-lg shadow-lg max-w-full h-auto filter hover:filter-none hover:scale-110 duration-1000 grayscale shadow-photo"
              />
            </div>
          </section>
        </main>
      </div>
    </>
  );
}

export default Home;
