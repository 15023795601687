import React, { useEffect } from "react";

import Home from "./pages/Home";
import Footer from "./components/Footer";
import Book from "./components/book";
import TestimonialCarousel from "./components/Test";

const MEASUREMENT_ID = "G-ZYJ11EE2DD";

function App() {
  useEffect(() => {
    // Directly use gtag function for sending a page view to GA4
    window.gtag("config", MEASUREMENT_ID, {
      page_path: window.location.pathname + window.location.search,
    });
  }, []);

  return (
    <div className="flex h-screen">
      <div className="flex-1 overflow-auto main-content bg-custom-blue flex flex-col">
        <div className="flex-grow">
          <Home />
          <Book />
          <TestimonialCarousel />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
